<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="custom-offer-main">
          <div class="close-div">
            <img  @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
                  src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
          </div>
          <div class="custom-offer-header">Özel Teklif</div>
          <div  class="custom-offer-inner">

            <p class="description-label">Açıklama</p>
            <div class="description-html">
              <span  v-html="offer.description"></span>
            </div>
            <select :disabled="true" v-model="selectedGig" class="super-drop" style="width: 100%; background-position-x: 97%; height: 50px;">
              <option :value="null">{{ gigTitle }}</option>
            </select>

            <div class="price-duration-body">
              <div class="price-duration" style="margin-top: 18px;">
                <p class="price-duration-title"> <img src="https://gcdn.bionluk.com/site/icon/tansel_offer_money.svg"/>Ücret </p>
                <p class="price-duration-value">{{ offer.price | specifyCurrency(units.currency) }}</p>
              </div>

              <div class="price-duration" style="margin-top: 10px;">
                <p class="price-duration-title"><img src="https://gcdn.bionluk.com/site/icon/tansel_offer_duration.svg"/>Süre</p>
                <p class="price-duration-value">{{ offer.duration | specifyPeriod(units.period, 'day') }}</p>
              </div>
            </div>
          </div>
          <div style="height: 80px; background-color: white; align-items: center; align-content: center; text-align: center;">
            <button v-if="offer.info.showBuyButton" @click="giveOrder()" class="super-button" style="margin-top: 10px;">
              Hemen Al
            </button>
            <p v-if="offer.info.showCancelButton && isCancelRequestDone" @click="cancelOffer()" class="cancel-button">İptal Et</p>
            <p v-if="offer.info.showCancelButton && !isCancelRequestDone"  style="padding-top: 10px;"> İşlem Yapılıyor...</p>

            <div class="custom-offer-info" v-if="offer.info.infoText.length">
              <img src="https://gcdn.bionluk.com/site/icon/tansel_offer_info.svg"/>
              <div class="info-text" v-if="offer.info.url.length" >{{ offer.info.infoText | getInfoText('text') }}
                <router-link class="info-text"
                             :to="offer.info.url"
                             @click.native="hide()"
                             style="font-weight: 600;">{{ offer.info.infoText | getInfoText('url') }}</router-link>
              </div>
              <p class="info-text" v-if="!offer.info.url.length">{{offer.info.infoText}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </transition>

</template>

<script>

  export default {
    name: "src-pages-modals-customOfferDetail-v1_mobile",
    data() {
      return {
        offer: null,
        isCancelRequestDone: true,
        selectedGig: null,
        gigTitle: ''
      }
    },

    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      giveOrder() {
        this.api.orders.giveOrder('offer', this.offer.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if (this.isMobile) {
                this.$router.push(result.data.redirect_url);
              } else {
                this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(result.data), "checkout", "modal_" + this.$store.state.modalComponentMap.checkout);
                this.trackConversionForCheckoutedOrders(result.data.total, "TRY");
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: result}});
              }
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      cancelOffer() {
        this.isCancelRequestDone = false;
        this.api.seller.cancelOffer(this.offer.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.isCancelRequestDone  = true;
              this.hide();
              this.$toasted.global.infoToast({description: result.message});
              this.EventBus.$emit('sendOfferCancelRequest', { offer: this.offer, isCancel: true});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },


    },

    computed: {
      getModalInfo() {
        if (!this.offer) {
          this.offer = this.$store.state.activeModal.info.data;
          this.gigTitle = this.offer.gig_title;
        }

        return true;
      },

      disableToSend() {
        return !this.description || !this.duration || !this.price || !this.selectedGig || !this.gigs.length
      }

    }
  }

</script>

<style scoped lang="scss">

  .modal-container {
    margin-top: 60px;
    width: calc(100vw - 21px) !important;
    max-width: 444px;
    height: calc(100% - 30px);
    max-height: 600px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    position: relative;
    padding: 0;
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .custom-offer-main {
    padding: 19px 12px 30px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    height: 100%;
  }

  .custom-offer-inner {

    border-radius: 2px;
    background-color: #f4f5f7;
    padding: 21px 15px 19px 15px;
    margin-top: 18px;
  }

  .custom-offer-header {
    font-size: 32px;
    font-weight: 600;
    color: #2d3640;
  }

  .description-label {
    font-size: 14px;
    color: #2d3640;
    margin-bottom: 16px;
    text-align: center;
  }

  .description-html {

    min-height: 100px;
    max-height: 150px;
    max-width: 525px;
    overflow-y: scroll;
  }

  .price-duration-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 22px;
    .price-duration {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 254px;
      height: 80px;
      background-color: #ffffff;
      border: solid 1px #dfe4ec;
    }
  }

  .price-duration-title {
    font-size: 14px;
    color: #8b95a1;
    margin-bottom: 4px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .price-duration-value {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .custom-offer-info {
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
    .info-text {
      font-size: 14px;
      font-style: italic;
      line-height: 1.71;
      color: #8b95a1;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }

  }
  .cancel-button {
    cursor: pointer;
    text-decoration: underline;
    padding-top: 10px;
    &:hover {
      font-weight: 600;
    }
  }
</style>
